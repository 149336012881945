<template>
  <div class="collection">
    <h2>我的收藏</h2>
    <a-menu :selected-keys="[current]" mode="horizontal" @click="onChange">
      <a-menu-item key="">全部</a-menu-item>
      <a-menu-item :key="item.id" v-for="item in menuList">{{ item.name }}</a-menu-item>
    </a-menu>
    <div class="collection-list" v-if="list.length > 0">
      <div class="item d-f j-b a-c" v-for="item in list" :key="item.id">
        <div class="detail-container d-f">
          <div class="img-con">
            <img
              src="@/assets/image/user_download_list_icon_word.png"
              v-if="item.fileTypeName === 'word'"
            />
            <img
              src="@/assets/image/user_download_list_icon_pdf.png"
              v-else-if="item.fileTypeName === 'pdf'"
            />
            <img
              src="@/assets/image/user_download_list_icon_ppt.png"
              v-else-if="item.fileTypeName === 'ppt'"
            />
            <img
              src="@/assets/image/user_download_list_icon_video.png"
              v-else-if="item.fileTypeName === 'video'"
            />
            <img
              src="@/assets/image/user_download_list_icon_zip.png"
              v-else-if="item.fileTypeName === 'package'"
            />
            <img src="@/assets/image/user_download_list_icon_other.png" v-else />
          </div>
          <div class="detail d-f f-d j-b">
            <div class="title" @click="toPreview(item)">
              {{ item.fileName }}
            </div>
            <div class="content">
              <span>{{ item.createTime }}</span>
              <span class="author">
                <img src="@/assets/image/secondary_details_list_size.png" />
                {{ item.fileSize | handleSize }}
              </span>
              <span class="author">
                <img src="@/assets/image/secondary_details_list_download.png" />
                {{ item.downloadCount }}次
              </span>
              <span class="author">
                <img src="@/assets/image/secondary_details_list_collection.png" />
                <span class="num">{{ item.collectionCount }}</span
                >次
              </span>
              <span class="author">
                <img src="@/assets/image/secondary_details_list_read.png" />
                {{ item.readCount }}次
              </span>
            </div>
          </div>
        </div>
        <a-button class="collection-again" @click="cancelCollection(item)"
          >取消收藏</a-button
        >
      </div>
      <div class="pagination-container">
        <a-pagination
          v-model="pageIndex"
          :pageSize="pageSize"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChangePage"
          :item-render="itemRender"
          class="pagination-content"
        />
      </div>
    </div>
    <div class="no-data d-f j-c" v-else>
      <img src="@/assets/image/user_no_data.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: "",
      navId: null,
      menuList: [],
      pageIndex: 1,
      pageSize: 4,
      total: null,
      list: [],
    };
  },
  filters: {
    handleSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
  },
  methods: {
    onChange(item) {
      this.current = item.key;
      this.list = [];
      this.pageIndex = 1;
      if (this.current != "") {
        this.navId = this.current;
      } else {
        this.navId = null;
      }
      this.getCollectionList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //分页器改变
    onChangePage(page, pageSize) {
      this.pageIndex = page;
      this.getCollectionList();
    },
    //收藏列表
    getCollectionList() {
      let params = {};
      params.pageNum = this.pageIndex;
      params.pageSize = this.pageSize;
      params.navId = this.navId;
      this.$api.getCollectionList(params).then((res) => {
        // console.log(res);
        if (res.code === 200) {
          this.total = res.total;
          this.list = res.rows;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //取消收藏
    cancelCollection(item) {
      this.$api.changeCollectionStatus(item.id).then((res) => {
        if (res.code === 200) {
          this.$message.success("取消收藏成功");
          this.getCollectionList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //去预览页
    toPreview(item) {
      this.$router.push({
        path: "/preview",
        query: { navId: item.navId, id: item.id },
      });
    },
  },
  created() {
    let navList = this.$ls.get("navList");
    this.menuList = navList;
    this.getCollectionList();
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.collection {
  width: 936px;
  height: 710px;
  background: #fff;
  padding: 24px 36px 0;
  box-sizing: border-box;
  border-radius: 4px;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
    margin-bottom: 24px;
  }
  .collection-list {
    width: 100%;
    box-sizing: border-box;
    padding-top: 12px;
    .item {
      width: 100%;
      border-bottom: 1px dashed #bebebe;
      padding: 24px 0;
      .detail-container {
        .img-con {
          width: 52px;
          height: 66px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detail {
          width: 550px;
          height: 60px;
          margin-left: 24px;
          .title {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
          .title:hover {
            color: @main-color;
          }
          .content {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            .author {
              margin-left: 24px;
              .num {
                color: @main-color;
              }
              img {
                width: 16px;
                margin-top: -1px;
                margin-right: 4px;
              }
            }
          }
        }
      }
      // .collection-again {
      // }
    }
    .pagination-container {
      margin-top: 24px;
      width: 100%;

      .pagination-content {
        flex: 1;
        text-align: right;

        .ant-pagination-prev a {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border: 1px solid #d9dee5;
          border-radius: 2px;
        }

        .ant-pagination-next .next {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border-radius: 2px;
          border: none;
          background-color: @main-color;
          color: #fff;
        }
      }
    }
  }
  .no-data {
    width: 100%;
    padding-top: 136px;
    box-sizing: border-box;
    img {
      width: 260px;
      height: 224px;
    }
  }
}
</style>
